import {
  ERRORS_INIT_STATE,
  SET_HAS_ERROR,
  SET_HAS_POLICY_DOCUMENT
} from "./types";

export default function errorReducer(state = ERRORS_INIT_STATE, action: any) {
  switch (action.type) {
    case SET_HAS_ERROR:
      return {...state, hasError: action.payload};
    case SET_HAS_POLICY_DOCUMENT:
      return {...state, hasPolicyDocument: action.payload};
    default:
      return state;
  }
}
