import React from 'react';
import {HeaderSection} from "../../components/primitive/headerSection";
import {IonCol, IonContent, IonGrid, IonImg, IonRow, IonText} from "@ionic/react";
import {Wrapper} from "../../../core/styles";
import {HAS_POLICY_DOCUMENT} from "../../images";
import {UniversalButton} from "../../components/primitive/buttons";
import {useHistory} from "react-router-dom";
import {EMAIL_VERIFICATION_ROUTE} from "../../business/enums/routes";

interface Props {
  icon?: any;
  title?: string;
  errorMessage?: string;
}

export const HasNotUser: React.FC<Props> = () => {

  const history = useHistory()

  return (
    <IonContent>
      <Wrapper className='app-container ion-padding'>

        <HeaderSection previewSteps={false}/>

        <IonGrid>
          <IonRow className="ion-text-center">
            <IonCol>

              <div style={{display: 'flex', justifyContent: 'center'}}>
                <IonImg style={{width: '90%'}} src={HAS_POLICY_DOCUMENT}/>
              </div>

              <IonRow>
                <IonText style={{fontSize: "22px", lineHeight: "28px", letterSpacing: "0.2px", fontWeight: "600"}}>
                  We couldn’t find an account with the email you provided.
                </IonText>
              </IonRow>

              <IonRow>
                <IonText style={{fontSize: "16px", lineHeight: "28px", letterSpacing: "0.2px"}}>
                  Please login with the email address you originally used to create an account
                </IonText>
              </IonRow>


              <UniversalButton
                value="BUTTON.LOGIN"
                onClick={() => history.push(EMAIL_VERIFICATION_ROUTE.path)}
              />

            </IonCol>
          </IonRow>
        </IonGrid>

      </Wrapper>
    </IonContent>
  );
};
