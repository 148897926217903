import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useAuth0} from "@auth0/auth0-react";
import {getResidentByAuth, searchAuthProfile} from "../../business/redux/reducers/resident/actions";
import {HeaderSection} from "../../components/primitive/headerSection";
import {CoverageStatusSection} from "../../components/primitive/coverageStatusSection";
import {UniversalButtonGroup} from "../../components/primitive/buttons";
import {WhatIsWaiverCoverageText} from "../../components/primitive/whatIsWaiverCoverageText";
import {Text} from "../../../core/components/primitives";
import {ErrorComponent} from "../../components/errors";
import {CoverageInformation} from "./coverageInformation";
import {Spinner} from "../../components/primitive/spinner";
import {useHistory} from "react-router-dom";
import {InformationModal} from "../../components/primitive/modals";
import {PerilsSection} from "../../components/primitive/perilsSection";
import {isExpired} from "./functionHelpers";
import {useDebounce} from "../../hooks";
import {PhoneCreatedSuccessModal, PhoneCreateModal} from "./modals";
import {StoreModel} from "../../business/models/store";
import {IonCol, IonContent, IonFooter, IonGrid, IonItemDivider, IonRow, IonTitle, IonToolbar} from "@ionic/react";
import {policyRecordTypeTypeValues, policyTypeValues} from "../../business/constants/resident";
import {Wrapper} from "../../../core/styles";
import {colors} from "../../business/constants/global";
import {coverageSwitchTypes} from "../../business/constants/startUrls";
import {CHOOSE_INSURANCE_ROUTE, SWITCH_TO_COVERAGE_CONFIRMATION_ROUTE} from "../../business/enums/routes";
import {managerService} from "../../services";


export const MyCoverage: React.FC = () => {

  const [userVerified, setUserVerified] = useState({type: 'phone_number', value: '', sent: false, inValidText: ''});

  const debounce = useDebounce(userVerified.value);

  const [checkPhoneOrEmail, setCheckPhoneOrEmail] = useState(null)
  const [receiveTextMessages, setReceiveTextMessages] = useState(true)

  useEffect(() => {
    // @ts-ignore
    searchAuthProfile({type: userVerified.type, value: userVerified.value})
      .then(r => setCheckPhoneOrEmail(r))
      .catch(() => managerService.error('Catch in searchAuthProfile method'));
  }, [debounce]);

  const [isOpenPhoneModal, setIsOpenPhoneModal] = useState(true);
  const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false);

  let history = useHistory();

  const [isOpen, setIsOpen] = useState(false)

  const active = 'ACTIVE';
  const pendingReview = 'PENDING_REVIEW';
  const expiring = 'EXPIRING';

  const { user } = useAuth0();
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch<any>(getResidentByAuth(user?.sub));
  }, []);

  const {resident} = useSelector((state: StoreModel) => state)

  useEffect(() => {
    setTimeout(() => {
      resident?.resident?.email_profile_exists && !resident?.resident?.phone && setIsOpenPhoneModal(true)
      // resident?.resident?.email_profile_exists && !resident?.resident?.phone_profile_exists && resident?.resident?.login_by_phone_allowed !== 't' &&  setIsOpenPhoneModal(true)
    }, 2000)
  }, [resident]);

  const [coverageInfo, setCoverageInfo] = useState({
    isShow: false,
    resident: resident?.resident,
    recordType: policyRecordTypeTypeValues.primary
  })

  if (resident === null) return <Spinner />;

  if (!resident || resident?.success === false) {
    return (
      <ErrorComponent
        title={"ERROR.SOMETHING_WENT_WRONG"}
        errorMessage={"MESSAGE.PLEASE_TRY_LATER"}
      />
    );
  }

  const isExpiring = isExpired(resident?.resident)
  const isWaiver = resident?.resident?.coverage_type === policyTypeValues.PDLW;

  const activeSectionType = (isExpiring && !isWaiver) ? expiring : active;

  const updateCoverageButton = [
    {
      color: colors.white,
      value: "BUTTON.UPDATE_COVERAGE",
      onClick: () => {
        managerService.click({
          component: managerService.components.myCoverage,
          buttonName: 'UPDATE_COVERAGE'
        })
        history.push(CHOOSE_INSURANCE_ROUTE.path)
      }
    }
  ];

  const switchToCoverageButton = [
    {
      color: isWaiver ? colors.white : colors.cyan,
      value: isWaiver ? "BUTTON.UPLOAD_RENTERS_INSURANCE" : "BUTTON.SWITCH_TO_WAIVER_COVERAGE",
      onClick: () => {
        managerService.click({
          component: managerService.components.myCoverage,
          buttonName: isWaiver ? 'UPLOAD_RENTERS_INSURANCE' : 'SWITCH_TO_WAIVER_COVERAGE'
        })
        history.push({
          pathname: SWITCH_TO_COVERAGE_CONFIRMATION_ROUTE.path,
          state: {type: isWaiver ? coverageSwitchTypes.pdlwToHo4 : coverageSwitchTypes.ho4ToPdlw}
        })
      }
    }
  ];

  const onSectionClick = recordType => {
    managerService.click({
      component: managerService.components.myCoverage,
      buttonName: 'VIEW_DETAILS'
    })
    return setCoverageInfo({
      isShow: true,
      recordType,
      resident: resident?.resident
    })
  }

  return (
    <>
      {resident?.success === true && (
        <IonContent>
          <Wrapper className="app-container ion-padding">

            <HeaderSection hasBackButton={coverageInfo.isShow} backClick={() => setCoverageInfo({...coverageInfo, isShow: false})} />

            {isOpenPhoneModal &&
              <PhoneCreateModal
                userVerified={userVerified}
                setUserVerified={setUserVerified}
                receiveTextMessages={receiveTextMessages}
                setReceiveTextMessages={setReceiveTextMessages}
                resident={resident}
                setIsOpenSuccessModal={setIsOpenSuccessModal}
                setIsOpenPhoneModal={setIsOpenPhoneModal}
                isOpenPhoneModal={isOpenPhoneModal}
                checkPhoneOrEmail={checkPhoneOrEmail}
              />}

            {
              coverageInfo.isShow &&
              <CoverageInformation
                coverageInfo={coverageInfo}
                setCoverageInfo={setCoverageInfo}
              />
            }

            {
              !coverageInfo.isShow &&
              <>
                <IonGrid>
                  {resident?.resident?.unit?.unit_no &&
                    <IonRow className="ion-padding-top ion-padding-bottom">
                      <IonCol className="ion-text-center">
                        <IonToolbar className="ion-no-padding">
                          <IonTitle size="small" className=" ion-text-center white-bg-color ion-title-medium">
                            <Text k={"TEXT.UNIT"}/>: {resident?.resident?.unit?.unit_no}</IonTitle>
                        </IonToolbar>
                      </IonCol>
                    </IonRow>}

                  <CoverageStatusSection
                    onClick={() => onSectionClick(policyRecordTypeTypeValues.primary)}
                    resident={resident?.resident}
                    sectionType={activeSectionType}
                    setIsOpen={setIsOpen}
                  />

                  {resident?.resident?.secondary && (
                    <CoverageStatusSection
                      onClick={() => onSectionClick(policyRecordTypeTypeValues.secondary)}
                      resident={resident.resident.secondary}
                      sectionType={pendingReview}
                      setIsOpen={setIsOpen}
                    />
                  )}

                </IonGrid>

                {!Boolean(resident?.resident?.secondary) && (
                  <IonFooter>
                    {!isWaiver && <>
                      <UniversalButtonGroup buttons={updateCoverageButton}/>
                      <IonItemDivider className="divider-or">
                        <div className="ion-text-center divider-or-cont"> or</div>
                      </IonItemDivider>
                    </>}
                    <UniversalButtonGroup buttons={switchToCoverageButton}/>
                    {!isWaiver && <WhatIsWaiverCoverageText setIsOpen={setIsOpen}/>}
                  </IonFooter>
                )}
              </>
            }

            <InformationModal
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              headerText={''}
              contextText={<PerilsSection/>}
            />
            {/*<InformationModal*/}
            {/*  isOpen={isOpenPhoneModal}*/}
            {/*  setIsOpen={setIsOpenPhoneModal}*/}
            {/*  hasCloseButton={false}*/}
            {/*  // headerText={'Please provide your cell phone, it would be easier to login next time'}*/}
            {/*  headerText={'Please provide your cell phone to be notified about the coverage status'}*/}
            {/*  contextText={<PhoneCreateModal*/}
            {/*    userVerified={userVerified}*/}
            {/*    setUserVerified={setUserVerified}*/}
            {/*    receiveTextMessages={receiveTextMessages}*/}
            {/*    setReceiveTextMessages={setReceiveTextMessages}*/}
            {/*    resident={resident}*/}
            {/*    setIsOpenSuccessModal={setIsOpenSuccessModal}*/}
            {/*    setIsOpenPhoneModal={setIsOpenPhoneModal}*/}
            {/*    checkPhoneOrEmail={checkPhoneOrEmail}*/}
            {/*  />}*/}
            {/*/>*/}

            <InformationModal
              isOpen={isOpenSuccessModal}
              setIsOpen={setIsOpenSuccessModal}
              hasCloseButton={false}
              contextText={<PhoneCreatedSuccessModal
                setIsOpenSuccessModal={setIsOpenSuccessModal}
              />}
            />

          </Wrapper>
        </IonContent>
      )}
    </>
  );
};

