import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {SelectInsuranceData} from "../selectInsuranceData";
import {syncLocalResident} from "../../business/redux/reducers/resident/actions";
import {uploadPolicy} from "../../business/redux/reducers/policyDocument/actions";
import {HeaderSection} from "../../components/primitive/headerSection";
import {PasswordForm} from "../../components/primitive/passwordForm";
import {useLocation} from "react-router-dom";
import {StoreModel} from "../../business/models/store";
import {IonContent, IonGrid, IonRow} from "@ionic/react";
import {Wrapper} from "../../../core/styles";

export const ChangeInsuranceProvider: React.FC = (): JSX.Element => {

  const stepNames = ['CHOOSE_INSURANCE', 'UPLOAD_POLICY', 'LOG_IN'];

  const [selectedInsuranceCompany, setSelectedInsuranceCompany] = useState({ key: "", value: "", text: "" })
  const [step, setStep] = useState(1)
  const [fileUrl, setFileUrl] = useState("")
  const [otherCompany, setOtherCompany] = useState("")
  const { resident, policyDocument } = useSelector((state: StoreModel) => state)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch<any>(syncLocalResident())
  },[])

  const selectedCompany = selectedInsuranceCompany.key === 'standard'
    ? selectedInsuranceCompany.text
    : selectedInsuranceCompany.value === 'other'
      ? ''
      : selectedInsuranceCompany.value;

  const activeStep = step === 4 ? 3 : (Boolean(selectedCompany) ? 2 : 1);

  return (
    <IonContent>
      {
        resident && resident.success && (
          <Wrapper className="app-container ion-padding">

            <HeaderSection
              activeStep={activeStep}
              stepNames={stepNames}
            />
            <IonRow>
              <IonGrid>
                {
                  (step < 4) &&
                  <SelectInsuranceData
                    step={step}
                    setStep={setStep}
                    selectedInsuranceCompany={selectedInsuranceCompany}
                    setSelectedInsuranceCompany={setSelectedInsuranceCompany}
                    uploadPolicy={uploadPolicy}
                    otherCompany={otherCompany}
                    setOtherCompany={setOtherCompany}
                    fileUrl={fileUrl}
                    setFileUrl={setFileUrl}
                    resident={resident?.resident}
                    hasButton={true}
                    recordType={resident.resident?.record_type}
                  />
                }

                {
                  step === 4 &&
                  <PasswordForm resident={resident.resident}/>
                }
              </IonGrid>
            </IonRow>
          </Wrapper>
        )
      }
    </IonContent>
  );
};
