import React, {useState} from "react";
import {UniversalButton} from "../../components/primitive/buttons";
import {useHistory, useLocation} from "react-router-dom";
import {HeaderSection} from "../../components/primitive/headerSection";
import {useAuth0} from "@auth0/auth0-react";
import {QuestionMarkIcon} from "../../components/primitive/icons";
import {InformationModal} from "../../components/primitive/modals";
import {PerilsSection} from "../../components/primitive/perilsSection";
import {IonCol, IonContent, IonFooter, IonGrid} from "@ionic/react";
import {colors} from "../../business/constants/global";
import {Wrapper} from "../../../core/styles";
import {
  MY_COVERAGE_ROUTE, PASSWORD_LESS_LOGIN_ROUTE,
  POLICY_INFORMATION_SENT_ROUTE
} from "../../business/enums/routes";
import {Heading} from "../../components/primitive/heading";
import {StyledLogo} from "../../components/primitive/styledLogo";
import {managerService} from "../../services";

export const PolicyInformationSent: React.FC = () => {

  const [isOpen, setIsOpen] = useState(false)

  const { isAuthenticated } = useAuth0();

  let {state}: any = useLocation()

  let history = useHistory()

  const redirectUrl = isAuthenticated
    ? MY_COVERAGE_ROUTE.path
    : {
      pathname: PASSWORD_LESS_LOGIN_ROUTE.path,
      state: {...state, prevUrl: POLICY_INFORMATION_SENT_ROUTE.path}
    }

    const clickHandler = () => {
      managerService.click({
        component: managerService.components.policyInformationSent,
        buttonName: 'CONTINUE'
      })
      history.push(redirectUrl)
    }

  return (
    <IonContent>
      <Wrapper className="app-container ion-padding">

        <HeaderSection
          hasBackButton={true}
          previewSteps={!isAuthenticated}
          activeStep={2}
        />

        <IonGrid className="start-page-top-info">
          <StyledLogo/>
          <Heading text={<>The policy has been<br/>submitted.</>} hasSuccessLogo={false}/>
        </IonGrid>
        <IonGrid className="start-page-top-info">
          <p className="ion-text-center ion-no-margin">
            Your coverage is being<br/>
            reviewed for compliance.<br/><br/>

            {isAuthenticated && (
              <>
                Current coverage will remain<br/>
                active until the new policy is<br/>
                approved.<br/>
              </>
            )}

            {!isAuthenticated && (
              <>
                Your until will be covered by<br/>
                <IonCol className="ion-text-center flex-center">
                  the &nbsp;
                  <span className={'flex-center'} style={{color: colors.cyan}}>
                    Waiver<QuestionMarkIcon onClick={() => setIsOpen(true)}/>
                  </span>&nbsp;
                  until it is
                </IonCol>
                approved.<br/>
              </>
            )}
          </p>
        </IonGrid>

        <IonFooter>
          <UniversalButton
            value={"BUTTON.CONTINUE"}
            disabled={false}
            onClick={clickHandler}
          />
        </IonFooter>
        <InformationModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          headerText={''}
          contextText={<PerilsSection/>}
        />
      </Wrapper>
    </IonContent>
  );
}
