import React from "react";
import {Logo} from "../../components/primitive/logo";
import {SuccessLogo} from "../../components/primitive/successLogo";
import {Text} from "../../../core/components/primitives";
import {useAuth0} from "@auth0/auth0-react";
import {UniversalButton} from "../../components/primitive/buttons";
import {useHistory} from "react-router-dom";
import {LogOutButton} from "../../components/primitive/buttons/logOutButton";
import {IonCol, IonContent, IonFooter, IonGrid, IonRow, IonToolbar} from "@ionic/react";
import {MY_COVERAGE_ROUTE} from "../../business/enums/routes";
import {Wrapper} from "../../../core/styles";
import {managerService} from "../../services";

export const PolicySent: React.FC = () => {
  const { isAuthenticated, logout } = useAuth0();
  let history = useHistory();
  const clickHandler = () => {
    managerService.click({
      component: managerService.components.policySent,
      buttonName: 'OK'
    })
    history.push(MY_COVERAGE_ROUTE.path)
  }
  return (
    <IonContent>
      <Wrapper className="app-container ion-padding">
        <IonGrid className="white-bg-head">
          <Logo />
          {isAuthenticated && <LogOutButton logout={logout} />}
        </IonGrid>
        <IonGrid className="ion-padding-top ion-padding-bottom ion-margin-bottom ion-margin-top">
          <IonRow className="ion-justify-content-center ion-padding-top ion-padding-bottom ">
            <IonCol size="3">
              <SuccessLogo />
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonCol className="ion-text-center" size="7">
              <IonToolbar>
                <h2 className="white-bg-color">
                  <Text k={"TEXT.THANK_YOU"} />
                </h2>
              </IonToolbar>
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonCol className="ion-text-center" size="7">
              <IonToolbar>
                <h2 className="white-bg-color">
                  { !isAuthenticated && <Text k={"TEXT.NOTIFY_VIA_EMAIL"} /> }
                  { isAuthenticated && <Text k={"TEXT.POLICY_SUBMITTED_BY_AUTH"} /> }

                </h2>
              </IonToolbar>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonFooter>
          <UniversalButton
            onClick={clickHandler}
            value={"BUTTON.OK"}
          />
        </IonFooter>
      </Wrapper>
    </IonContent>
  );
};
