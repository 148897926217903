import React, {useEffect, useState} from "react";
import {HeaderSection} from "../../components/primitive/headerSection";
import {IonCol, IonContent, IonFooter, IonGrid, IonRow} from "@ionic/react";
import {Wrapper} from "../../../core/styles";
import {makeStyles} from "@material-ui/core/styles";
import {style} from "../../components/primitive/coverageStatusSection/style";
import {colors} from "../../business/constants/global";
import {UniversalButtonGroup} from "../../components/primitive/buttons";
import {useHistory} from "react-router-dom";
import {Checkbox, Divider} from '@mui/material';
import {USER_INFORMATION_ROUTE} from "../../business/enums/routes";
import {useAuth0} from "@auth0/auth0-react";
import {useDispatch, useSelector} from "react-redux";
import {getResidentByAuth, updateResidentProfile} from "../../business/redux/reducers/resident/actions";
import {StoreModel} from "../../business/models/store";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import {managerService} from "../../services";

export const Preferences: React.FC = (): JSX.Element => {

  const dispatch = useDispatch();

  const {user} = useAuth0();

  useEffect(() => {
    if (user?.sub) {
      dispatch<any>(getResidentByAuth(user?.sub));
    }
  }, [user?.sub]);

  const {resident} = useSelector((state: StoreModel) => state)


  const [updatedData, setUpdatedData] = useState();

  useEffect(() => {
    if (user?.sub) {
      setUpdatedData({
        // @ts-ignore
        // login_by_phone_allowed: resident?.resident?.login_by_phone_allowed,
        i_want_to_receive_text_messages: resident?.resident?.i_want_to_receive_text_messages
      });
    }
  }, [
    // resident?.resident?.login_by_phone_allowed,
    resident?.resident?.i_want_to_receive_text_messages
  ]);

  // @ts-ignore
  const classes = makeStyles(() => style(colors.cyan))();

  const {logout} = useAuth0();

  let history = useHistory();

  const buttons = [
    {
      color: colors.cyan,
      value: "BUTTON.SAVE",
      // onClick: () => {}
      // @ts-ignore
      onClick: () => dispatch(updateResidentProfile(
        resident?.resident?.uuid,
        updatedData,
        () => {
          managerService.success(managerService.types.success, {
            action: managerService.actions.updateResidentProfile,
          })
          history.push(USER_INFORMATION_ROUTE.path)
        }
      ))
    },
    {
      value: "BUTTON.BACK",
      color: colors.white,
      onClick: () => {
        managerService.click({
          component: managerService.components.preferences,
          buttonName: 'BACK'
        })
        history.push(USER_INFORMATION_ROUTE.path)
      }
    },
  ]

  // @ts-ignore
  const onCheck = e => setUpdatedData(prev => ({...prev, [e.target.name]: e.target.checked ? "t" : "f"}))

  const appLogout = () => logout({returnTo: window.location.origin});

  return (
    <IonContent>
      <Wrapper className="app-container ion-padding">

        <IonGrid className="gray-bg-head ion-margin-top">
          <HeaderSection hasBackButton={true} previewSteps={false}/>

          <IonRow>
            <IonCol className={'ion-text-center'}>
              <h2 className={classes.mediumTitle}>Preferences</h2>
            </IonCol>
          </IonRow>

        </IonGrid>


        <IonRow style={{marginTop: 50, paddingBottom: 15, borderBottomStyle: 'dashed', borderWidth: 2, borderColor: '#2D2D2D', marginBottom: 40}}>
          <IonCol className={'ion-text-center flex ion-align-items-center ion-no-padding'}>
            <LocalPhoneIcon/>
            <h2 style={{marginLeft: 20}} className={classes.mediumTitle}>Text Message Preferences</h2>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol size="1">

            <Checkbox
              // @ts-ignore
              checked={updatedData?.i_want_to_receive_text_messages === 't'}
              onChange={onCheck}
              name={'i_want_to_receive_text_messages'}
              style={{padding: 0}}
            />
          </IonCol>
          <IonCol size="11">I want to receive text messages about the status of my renters insurance policy</IonCol>
        </IonRow>

        {/*<IonRow>*/}
        {/*  <IonCol size="1">*/}

        {/*    <Checkbox*/}
        {/*      // @ts-ignore*/}
        {/*      checked={updatedData?.login_by_phone_allowed === 't'}*/}
        {/*      onChange={onCheck}*/}
        {/*      name={'login_by_phone_allowed'}*/}
        {/*      style={{padding: 0}}*/}
        {/*    />*/}
        {/*  </IonCol>*/}
        {/*  <IonCol size="11">Use my mobile phone number to login to renters insurance program</IonCol>*/}
        {/*</IonRow>*/}


        <IonFooter style={{marginTop: 200}}>
          <UniversalButtonGroup
            buttons={buttons}
          />
        </IonFooter>
      </Wrapper>
    </IonContent>
  );
};
