import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useAuth0} from "@auth0/auth0-react";
import {HeaderSection} from "../../components/primitive/headerSection";
import {validateEmail} from "../../../core/business/helpers/input";
import {UniversalButton} from "../../components/primitive/buttons";
import {makeStyles} from "@material-ui/core/styles";
import {Heading} from "../../components/primitive/heading";
import {searchAuthProfile} from "../../business/redux/reducers/resident/actions";
import {contactFormStyle} from "./styles";
import {colors} from "../../business/constants/global";
import {IonContent, IonFooter, IonInput, IonText} from "@ionic/react";
import {Wrapper} from "../../../core/styles";
import {PERSONAL_ACCESS_ROUTE} from "../../business/enums/routes";
import {enqueueSnackbar} from "notistack";
import {
  CLEAR_LOADING_BY_MIDDLE_TYPE,
  SET_LOADING_BY_MIDDLE_TYPE
} from "../../business/redux/reducers/loadingByType/types";
import {managerService} from "../../services";

export const EmailLogin = () => {

  let history: any = useHistory()
  let location: any = useLocation()

  const dispatch = useDispatch();

  const {loginWithRedirect} = useAuth0()

  const useStyles = makeStyles(() => contactFormStyle);

  const classes = useStyles();

  const [email, setEmail] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [ready, setReady] = useState(false);

  useEffect(( ) => {

    setEmail(location.search?.split('?email=')?.at(1) || '');

    setReady(true);

  }, []);

  if (!ready) return;

  const errorText = (message: any) => (
    <IonText color="danger" className="ion-margin-top">
      <span role="alert">{message}</span>
    </IonText>
  )

  const backIconClick = () => {
    managerService.click({
      component: managerService.components.emailLogin,
      buttonName: 'BACK_ICON'
    })
    history.push(PERSONAL_ACCESS_ROUTE.path)
  }

  const headingText = <h1>Please enter your <br/>email address to <br/>Login</h1>

  const validated = validateEmail(email)

  const changeHandler = (e: any) => setEmail(e.target.value)

  const clickHandler = () => {

    if (!email) return setErrorMessage('* The field is required');
    if (!validated) return setErrorMessage('* Invalid email address format');

    managerService.click({
      component: managerService.components.emailLogin,
      buttonName: 'CONTINUE'
    })
    dispatch({
      type: SET_LOADING_BY_MIDDLE_TYPE,
      loadingData: {
        loading: true,
        modalData: {
          message: "Please wait"
        }
      }
    });
    searchAuthProfile({type: 'email', value: email})
      .then(r => {
        return (
          r.status === 'success'
            ? loginWithRedirect({connection: 'email', login_hint: email})
            : setErrorMessage(r?.message)
        )
      })
      .catch(e => {
        managerService.error('Catch in searchAuthProfile method')
        enqueueSnackbar({variant: "error", message: e.message})
      })
      .finally(() => {
        dispatch({
          type: CLEAR_LOADING_BY_MIDDLE_TYPE,
          loadingData: null
        });
      })
  }

  return (
    <IonContent>
      <Wrapper className='app-container ion-padding'>

        <HeaderSection
          previewSteps={false}
          background={colors.grey}
          hasBackButton={false}
          backClick={backIconClick}
        />

        <Heading hasSuccessLogo={false} text={headingText}/>

        <div className={classes.root}>
          <div className={classes.inputGroup}>
            <IonInput
              mode="ios"
              type={'email'}
              placeholder={'Email Address'}
              value={email}
              onIonInput={changeHandler}
              className="ion-border ion-border-radius4"
            />
            {errorText(errorMessage)}
          </div>
        </div>
        <IonFooter>
          <UniversalButton
            value={"BUTTON.CONTINUE"}
            onClick={clickHandler}
          />

        </IonFooter>

      </Wrapper>
    </IonContent>
  );
}
