import React, { useState, useEffect } from 'react';
import { AutoSizer, Column, Table, InfiniteLoader } from 'react-virtualized';
import { Checkbox, TableSortLabel } from '@mui/material';
import TableCell from '@mui/material/TableCell';

import { Text } from '../../../intl';
import { SORT } from '../../../business/enums';
import { util } from '../../../business/helpers';
import { generateSelects } from './utils';
import { Vwrapper } from './styles';
import { TableDisplayModel, TableStylesModel, defaultDisplay, defaultStyles } from '../../../business/models/table';
import CellModel from '../../../business/models/cell';

interface TableProps {
  display: TableDisplayModel;
  styles: TableStylesModel;
  TABLE: Array<CellModel>;
  data: Array<any>;
  onChangePage?: Function;
  onLoadNextPage?: Function;
  onChangeSort?: Function;
  onSelection?: Function;
  onClickRow?: (r) => void;
  itemActions?: { Function };
  resetSelection?: boolean;
}

const VirtualizedTable: React.FC<TableProps> = React.memo(
  ({
    display = defaultDisplay,
    styles = defaultStyles,
    TABLE,
    data,
    onLoadNextPage = () => {},
    onSelection,
    onClickRow,
    onChangeSort = () => {},
    itemActions,
    resetSelection = false,
  }) => {
    const {
      pageSize: rowsPerPage,
      totalDataSize = defaultDisplay.totalDataSize,
      sortKey,
      sortOrder,
      isSelectable,
    } = display;
    const {
      color,
      fontColor,
      isStripped,
      cellPadding,
      minWidth,
      maxWidth,
      fontSize,
      borderColor,
      headerHeight = 40,
      rowHeight = 40,
      checkWidth,
      additionalHeight,
    } = styles;
    const pageSize = rowsPerPage === 0 ? totalDataSize : rowsPerPage || totalDataSize;
    const currentDataSize = data.length;
    const [checkedRows, setCheckedRows] = useState<Array<boolean>>(generateSelects(pageSize));
    const [selectAll, setSelectAll] = useState<boolean>(false);

    useEffect(() => {
      setCheckedRows(generateSelects(pageSize));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetSelection]);

    const checkRow = (i) => {
      const rows = [...checkedRows];
      rows[i] = !rows[i];
      setCheckedRows(rows);
      if (onSelection) onSelection(rows);
    };

    const checkAllRows = () => {
      const newSelectAll = !selectAll;
      const rows = generateSelects(currentDataSize, newSelectAll);
      setCheckedRows(rows);
      if (onSelection) onSelection(rows);
      setSelectAll(newSelectAll);
    };

    if (!currentDataSize) {
      return <div></div>;
    }

    const COLUMNS = isSelectable
      ? [
          {
            width: checkWidth || 60,
            label: 'select',
            selectable: true,
            value: 'select',
          },
          ...TABLE.map((t) => ({
            ...t,
          })),
        ]
      : TABLE;

    const headerRenderer = ({ col: cell, index }) => {
      const cellSortKey = cell.sortKey || cell.value;
      return cell.selectable ? (
        <TableCell component="div" className="center">
          <span className="selectCol">
            <Checkbox checked={selectAll} className="select-all checkbox" onClick={checkAllRows} />
          </span>
        </TableCell>
      ) : (
        <TableCell component="div" className={`${cell.align || ''} ${cell.className || ''}`}>
          {cell.sortable && (
            <TableSortLabel
              active={sortKey === cellSortKey}
              direction={sortOrder === SORT.ASC ? 'asc' : 'desc'}
              onClick={() => {
                onChangeSort(cellSortKey, sortKey !== cellSortKey ? '' : util.changeSort(sortOrder));
              }}>
              <Text k={cell.label} />
            </TableSortLabel>
          )}
          {!cell.sortable && <Text k={cell.label} />}
        </TableCell>
      );
    };

    const cellRenderer = ({ rowData: row, col: cell, rowIndex }) => {
      return cell.selectable ? (
        <TableCell component="div" className="center">
          <Checkbox
            className="checkbox"
            checked={checkedRows[rowIndex] ? checkedRows[rowIndex] : false}
            onClick={() => checkRow(rowIndex)}
          />
        </TableCell>
      ) : (
        <TableCell component="div" className={`${cell.align || ''} ${cell.className || ''}`}>
          {cell.component && cell.component(row, itemActions)}
          {!cell.component && row[cell['value']]}
        </TableCell>
      );
    };

    const isRowLoaded = ({ index }) => {
      return !!data[index];
    };

    return (
      <Vwrapper
        minWidth={minWidth}
        maxWidth={maxWidth}
        additionalHeight={additionalHeight}
        headerColor={color}
        fontSize={fontSize}
        fontColor={fontColor}
        cellPadding={cellPadding}
        borderColor={borderColor}
        stripped={isStripped}>
        <AutoSizer>
          {({ height, width }) => (
            <InfiniteLoader
              isRowLoaded={isRowLoaded}
              loadMoreRows={onLoadNextPage}
              rowCount={totalDataSize}
              threshold={10}>
              {({ registerChild, onRowsRendered }) => (
                <Table
                  ref={registerChild}
                  height={height}
                  width={width}
                  rowHeight={rowHeight}
                  gridStyle={{
                    direction: 'inherit',
                  }}
                  columns={{ COLUMNS }}
                  headerHeight={headerHeight!}
                  className="virtualized-table"
                  rowCount={currentDataSize}
                  overscanRowCount={20}
                  onRowsRendered={onRowsRendered}
                  rowGetter={({ index }) => data[index]}
                  rowClassName="virtualized-row">
                  {COLUMNS.map((col, index) => {
                    return (
                      <Column
                        key={index}
                        width={col.width}
                        headerRenderer={(props) => headerRenderer({ ...props, col, index })}
                        cellRenderer={(props) => cellRenderer({ ...props, col, index })}
                        dataKey={col.value}
                      />
                    );
                  })}
                </Table>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      </Vwrapper>
    );
  },
);

export default VirtualizedTable;
