import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {Text} from "../../../core/components/primitives";
import {UniversalButtonGroup} from "../../components/primitive/buttons";
import {fetchResident} from "../../business/redux/reducers/resident/actions";
import {ErrorComponent} from "../../components/errors";
import {HeaderSection} from "../../components/primitive/headerSection";
import {StoreModel} from "../../business/models/store";
import {IdParam} from "../../business/types";
import {Wrapper} from "../../../core/styles";
import {IonCol, IonContent, IonFooter, IonGrid, IonRow} from "@ionic/react";
import {CHAT_ROUTE, COVERAGE_ROUTE, MY_COVERAGE_ROUTE} from "../../business/enums/routes";
import {colors} from "../../business/constants/global";
import {startUrls} from "../../business/constants/startUrls";
import {managerService} from "../../services";

export const AddressConfirmation: React.FC = () => {
  let history = useHistory()
  const params = useParams<IdParam>();
  const dispatch = useDispatch();
  const {resident} = useSelector((state: StoreModel) => state);

  useEffect(() => {
    dispatch<any>(fetchResident(params));
  }, []);

  if (Boolean(resident?.resident?.auth0_sub))
    history.push(MY_COVERAGE_ROUTE.path)

  const buttons = [
    {
      color: colors.cyan,
      value: "BUTTON.YES_CONTINUE",
      onClick: () => {
        managerService.click({
          component: managerService.components.addressConfirmation,
          buttonName: 'YES_CONTINUE'
        })
        history.push({
          pathname: COVERAGE_ROUTE.path,
          state: {startUrl: startUrls.addressConfirmation}
        })
      }
    },
    {
      value: "BUTTON.NO_GET_HELP",
      color: colors.white,
      onClick: () => {
        managerService.click({
          component: managerService.components.addressConfirmation,
          buttonName: 'NO_GET_HELP'
        })
        history.push(`${CHAT_ROUTE.path}/${resident?.resident?.uuid}`)
      }
    },
  ];

  return (
    <>
      {Boolean(resident) && !Boolean(resident.success) && <ErrorComponent />}

      {Boolean(resident) && Boolean(resident.success) && (
        <IonContent>
          <Wrapper className="app-container ion-padding">
            <IonGrid className="gray-bg-head address-header">

              <HeaderSection previewSteps={false} />

              <IonRow>
                <IonCol className="ion-text-center">
                  <h2>
                    <Text k={"TEXT.CONFIRM_YOUR_ADDRESS"} />
                  </h2>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonGrid>
              <IonRow>
                <IonCol className="ion-text-center">
                  <p className="address-line-info"><Text k={"TEXT.UNIT"} /> #{resident?.resident?.unit?.unit_no}</p>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol className="ion-text-center">
                  <h2>{resident?.resident?.unit?.address}</h2>
                </IonCol>
              </IonRow>
              <IonFooter>
                <UniversalButtonGroup buttons={buttons} />
              </IonFooter>
            </IonGrid>
          </Wrapper>
        </IonContent>
      )}
    </>
  );
};
