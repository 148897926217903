import {fetchApi} from "../../../../../core/business/services/api";
import {Dispatch} from "redux";
import propertyNamesMock from "../../../mocks/propertyNames.json";
import {configs} from "../../../../../configs";
import {FETCH_PROPERTY_NAMES_SUCCESS} from "./types";
import {CLEAR_LOADING_BY_MIDDLE_TYPE, SET_LOADING_BY_MIDDLE_TYPE} from "../loadingByType/types";
import {managerService} from "../../../../services";

export const getPropertyNamesByUrl = pathname => async  (dispatch: Dispatch) => {

  try {
    dispatch({
      type: SET_LOADING_BY_MIDDLE_TYPE,
      loadingData: {
        loading: true,
        modalData: {
          message: "Please wait"
        }
      }
    });

    if (!pathname)
      return null

    const response = await fetchApi({
      url: `${configs.api.propertyNames.general}/${pathname}`,
      method: "GET",
      mockData: { success: true, property_names: propertyNamesMock }
    });

    dispatch({
      type: CLEAR_LOADING_BY_MIDDLE_TYPE,
      loadingData: null
    });

    dispatch({
      type: FETCH_PROPERTY_NAMES_SUCCESS,
      propertyNames: response
    });
  } catch (e) {
    managerService.error('In getPropertyNamesByUrl method')
  }
}
