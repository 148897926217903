import React from 'react';
import {HeaderSection} from "../../components/primitive/headerSection";
import {makeStyles} from "@material-ui/core/styles";
import {Text} from "../../../core/components/primitives";
import {IonCol, IonContent, IonGrid, IonImg, IonRow, IonText} from "@ionic/react";
import {Wrapper} from "../../../core/styles";
import {personalFormStyle} from "../addYourName/styles";
import {HAS_POLICY_DOCUMENT} from "../../images";
import {UniversalButton} from "../../components/primitive/buttons";
import {useAuth0} from "@auth0/auth0-react";

interface Props {
  icon?: any;
  title?: string;
  errorMessage?: string;
}

export const HasPolicy: React.FC<Props> = () => {

  const {loginWithRedirect} = useAuth0()

  return (
    <IonContent>
      <Wrapper className='app-container ion-padding'>

        <HeaderSection previewSteps={false}/>

        <IonGrid>
          <IonRow className="ion-text-center">
            <IonCol>

              <div style={{display: 'flex', justifyContent: 'center'}}>
                <IonImg style={{width: '90%'}} src={HAS_POLICY_DOCUMENT}/>
              </div>

              <IonRow>
                <IonText style={{fontSize: "22px", lineHeight: "28px", letterSpacing: "0.2px", fontWeight: "600"}}>
                  Your unit already has a compliant policy uploaded
                </IonText>
              </IonRow>

              <IonRow>
                <IonText style={{fontSize: "16px", lineHeight: "28px", letterSpacing: "0.2px"}}>
                  Please login with the email address you or your roommate originally used to upload the policy.
                </IonText>
              </IonRow>


              <UniversalButton
                value="BUTTON.LOGIN"
                onClick={loginWithRedirect}
              />

            </IonCol>
          </IonRow>
        </IonGrid>

      </Wrapper>
    </IonContent>
  );
};
