import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {syncLocalResident} from "../../business/redux/reducers/resident/actions";
import {HeaderSection} from "../../components/primitive/headerSection";
import {useHistory} from "react-router-dom";
import {StoreModel} from "../../business/models/store";
import {
  COVERAGE_ROUTE, EMAIL_VERIFICATION_ROUTE,
  PASSWORD_LESS_LOGIN_ROUTE,
  PERSONAL_ACCESS_ROUTE, PHONE_VERIFICATION_ROUTE
} from "../../business/enums/routes";
import {IonCol, IonContent, IonFooter, IonGrid, IonRow} from "@ionic/react";
import {Wrapper} from "../../../core/styles";
import {colors} from "../../business/constants/global";
import {coverageSwitchTypes, startUrls} from "../../business/constants/startUrls";
import {useAuth0} from "@auth0/auth0-react";
import {setChatIcon} from "../../business/helpers/util";
import Typography from "@mui/material/Typography";
import {UniversalButtonGroup} from "../../components/primitive/buttons";
import {Heading} from "../../components/primitive/heading";
import {managerService} from "../../services";


export const PasswordLessLogin = () => {

  let history: any = useHistory()

  let {state}: any = history.location

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch<any>(syncLocalResident())
  }, [])

  const backButtonClick = () => {
    managerService.click({
      component: managerService.components.passwordLessLogin,
      buttonName: 'BACK_ICON'
    })
    if (state?.startUrl === startUrls.addressConfirmation) {
      return history.goBack();
    }else if (state?.startUrl === startUrls.personalAccess) {
      return history.push({
        pathname: COVERAGE_ROUTE.path,
        state: {
          startUrl: startUrls.personalAccess,
          prevUrl: PASSWORD_LESS_LOGIN_ROUTE.path,
          residentData: state.residentData
        }
      })
    }
  }

  const buttons = [
    // {
    //   color: colors.white,
    //   value: "BUTTON.LOG_IN_VIA_EMAIL_WITH_POPUP",
    //   onClick: () => loginWithPopup({connection: 'email'})
    // },
    {
      color: state?.prevUrl === PERSONAL_ACCESS_ROUTE.path ? colors.white : colors.cyan,
      value: "BUTTON.LOG_IN",
      onClick: () => {
        managerService.click({
          component: managerService.components.passwordLessLogin,
          buttonName: 'LOG_IN'
        })
        history.push({
          pathname: EMAIL_VERIFICATION_ROUTE.path,
          state: {
            startUrl: startUrls.personalAccess,
            prevUrl: PASSWORD_LESS_LOGIN_ROUTE.path,
            residentData: state.residentData
          }
        })
      }
        // loginWithRedirect({connection: 'email', login_hint:  'armanrll112@gmail.com'})
    },
    // {
    //   color: colors.white,
    //   value: "BUTTON.LOG_IN_VIA_SMS_WITH_POPUP",
    //   onClick: () => loginWithPopup({connection: 'sms'})
    // },
    // {
    //   color: colors.white,
    //   value: "BUTTON.LOG_IN_VIA_SMS_WITH_REDIRECT",
    //   onClick: () => loginWithRedirect({connection: 'sms',login_hint:  '+37493444785'})
    // },
  ];

  setChatIcon();

  const {resident} = useSelector((state: StoreModel) => state)

  return (
    // resident && resident.success && (
      <IonContent>
        <Wrapper className="app-container ion-padding">

          <HeaderSection
            previewSteps={state?.startUrl === startUrls.personalAccess}
            activeStep={3}
            hasBackButton={true}
            background={colors.grey}
            backClick={backButtonClick}
          />

          {state?.prevUrl === PERSONAL_ACCESS_ROUTE.path &&
            <>
              <HeaderSection previewSteps={false} />

              <IonGrid className='start-page-header'>
                <p className='ion-text-center'>A tool to manage your renters insurance coverage</p>
              </IonGrid>

              <Typography
                align={'center'}
                variant={'h5'}
              >Please choose how you</Typography>

              <Typography
                align={'center'}
                variant={'h5'}
              >want to get the access</Typography>
            </>
          }

          {state?.prevUrl !== PERSONAL_ACCESS_ROUTE.path &&
            <>
              <Heading text={<b>Success!</b>}/>

              <IonRow className="ion-justify-content-center ion-padding-bottom ion-margin-bottom">
                <IonCol size="10">
                    <p className="ion-text-center alert-info-text">If you want to see details of your</p>
                    <p className="ion-text-center alert-info-text">coverage, please log in.</p>
                </IonCol>
              </IonRow>
            </>
          }

          <IonFooter style={{marginTop: 25}}>

            <UniversalButtonGroup buttons={buttons}/>

          </IonFooter>

        </Wrapper>
      </IonContent>
    // )
  );
};
