import React, {useState, useEffect} from "react";
import {Provider} from "react-redux";
import intl from "react-intl-universal";
// import "intl/locale-data/jsonp/en.js";
import {setupIonicReact} from "@ionic/react";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {configs} from "../../configs";
import store from "../../product/business/redux/";
import AppInit from "./appInit";
import "@ionic/react/css/core.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import "../../core/styles/global.scss";
import "../../product/styles/general.scss";
import RootApp from "../../core/theme";
import {ToasterManager} from "../../core/components/widgets";
import {clog} from "../../core/business/helpers/util";
import {managerService} from "../services";
import {useAuth0} from "@auth0/auth0-react";

const locales = {
  "en-US": require("../../core/intl/en.json")
};

setupIonicReact({});

const {theme: colors} = configs;
const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary
    },
    secondary: {
      main: colors.secondary
    },
    success: {
      main: colors.success
    },
    warning: {
      main: colors.warning
    },
    error: {
      main: colors.danger
    }
  },
  typography: {
    htmlFontSize: configs.theme.fontSize,
    fontFamily: configs.theme.fontFamily
  }
});

const App: React.FC = () => {

  clog("ENV", process.env);

  const [initDone, setInitDone] = useState(false);

  useEffect(() => {
    managerService.init()
    // managerService.log()
    intl.init({currentLocale: "en-US", locales})
      .then(() => {
        setInitDone(true);
      });
    intl.load({"en-US": require("../intl/en.json")});
  }, []);

  if (!initDone) return null;

  return (
    <Provider store={store}>
      <RootApp id="root-app">
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <AppInit/>
          </LocalizationProvider>
        </ThemeProvider>
      </RootApp>
      <ToasterManager/>
    </Provider>
  );
};

export default App;
