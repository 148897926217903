import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {UniversalButton} from "../../components/primitive/buttons";
import {syncLocalResident} from "../../business/redux/reducers/resident/actions";
import {useAuth0} from "@auth0/auth0-react";
import {useLocation} from "react-router-dom";
import {HeaderSection} from "../../components/primitive/headerSection";
import {Heading} from "../../components/primitive/heading";
import {ErrorComponent} from "../../components/errors";
import {StoreModel} from "../../business/models/store";
import {Wrapper} from "../../../core/styles";
import {IonCol, IonContent, IonFooter, IonGrid, IonRow} from "@ionic/react";
import {auth0Configs} from "../../../configs";
import {startUrls} from "../../business/constants/startUrls";
import {managerService} from "../../services";

export const PasswordApproved: React.FC = (): JSX.Element => {

  let {state}: any = useLocation();

  const {audience} = auth0Configs;
  const {loginWithRedirect} = useAuth0();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch<any>(syncLocalResident());
  }, []);

  const {resident} = useSelector((state: StoreModel) => state);

  if (!state) return <ErrorComponent />

  return (
    <>
      {
        resident && resident.success && resident.resident && (
          <IonContent>
            <Wrapper className="app-container ion-padding">

              <HeaderSection activeStep={3} previewSteps={state?.startUrl === startUrls.personalAccess}/>
              <Heading text={'Thank you for registering. Your password has been saved.'}/>

              <IonGrid className="ion-padding-top ion-padding-bottom ion-margin-bottom ion-margin-top">
                <IonRow className="ion-justify-content-center">
                  <IonCol className="ion-text-center white-bg-color ion-no-padding" size="7">
                    To track your policy status, please log in below
                  </IonCol>
                </IonRow>
              </IonGrid>

              <IonFooter>
                <UniversalButton
                  value={"BUTTON.LOGIN"}
                  onClick={() => {
                    managerService.click({
                      component: managerService.components.myCoverage,
                      buttonName: 'LOGIN'
                    })
                    loginWithRedirect({audience})
                  }}
                />
              </IonFooter>

            </Wrapper>
          </IonContent>
        )
      }
    </>
  );
};
