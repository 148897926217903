import React, {useEffect} from "react";
import {NormalLayout} from "../components/layouts";
import {Spinner} from "../components/primitive/spinner";
import {connect, useSelector} from "react-redux";
import {dialog} from "../../core/components/reusables";
import {initActions as loadAppAction} from '../business/redux/actions';
import {IonLoading} from "@ionic/react";
import {StoreModel} from "../business/models/store";
import LoadingModel from "../../core/business/models/loading";
import * as Sentry from "@sentry/react";

interface AppInitProps {
  initSuccess: boolean;
  loadApp: Function;
  loading: LoadingModel;
}

const AppInit: React.FC<AppInitProps> = ({ initSuccess, loading, loadApp }) => {


  Sentry.init({
    dsn: "https://7ae0102f65444a038a1f90ad8bf092c4@o543907.ingest.sentry.io/6423854",
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      // Sentry.replayIntegration({
      //   maskAllText: false,
      //   blockAllMedia: false,
      // })
    ]
  });

  // logging example, keep DO NOT DELETE !!!
  // captureMessage("Something happened", "info");

  const { loadingByType } = useSelector((state: StoreModel) => state)

  useEffect(() => {
    loadApp();
  }, [loadApp]);
  const { hasError, isLoading, loadingText } = loading;

  if (initSuccess === false && hasError) {
    dialog.alert({
      title: 'ERROR.NETWORK',
      message: 'MESSAGE.INIT_FAIL',
    });
    return null;
  }

  // const fallback = <FallbackComponent />;

  // Keyboard.addListener('keyboardWillShow', info => {
  //   console.log('keyboard will show with height:', info.keyboardHeight);
  // });
  //
  // Keyboard.addListener('keyboardDidShow', info => {
  //   console.log('keyboard did show with height:', info.keyboardHeight);
  // });
  //
  // Keyboard.addListener('keyboardWillHide', () => {
  //   console.log('keyboard will hide');
  // });
  //
  // Keyboard.addListener('keyboardDidHide', () => {
  //   console.log('keyboard did hide');
  // });

  const Router: any = NormalLayout;

  return (
    <>
      {isLoading && <Spinner loadingText={loadingText} />}
      <IonLoading
        cssClass='image-upload-loading'
        spinner="crescent"
        isOpen={loadingByType?.loading}
        message={loadingByType?.modalData?.message || 'Please wait - the image will load soon.'}
      />
      {/*{initSuccess &&*/}
      {/*  <Sentry.ErrorBoundary fallback={fallback} showDialog>*/}
          <Router />
        {/*</Sentry.ErrorBoundary>}*/}
    </>
  );
};

const mapStateToProps = state => ({
  initSuccess: state.init.initSuccess,
  loading: state.loading
});

const mapDispatchToProps = {
  loadApp: loadAppAction.loadApp,
};

const connected: any = connect(mapStateToProps, mapDispatchToProps)(AppInit);

export default connected;
