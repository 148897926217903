import SmartLook from "smartlook-client";
// import TagManager from "react-gtm-module";
import TagManager from "react-gtm-module/dist/TagManager";
import {clog} from "../../core/business/helpers";

type ClickParams = {
  component: string
  buttonName: string
}

type RecordParams = {
  component: string
  message: string
}

type SuccessParams = {
  action: string
}

export const managerService = {
  init: () => {
    // @ts-ignore
    SmartLook.init(process.env.REACT_APP_SMART_LOOK_KEY, {region: 'eu', debug: true});
    TagManager.initialize({gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID})
    // @ts-ignore
    // window._kmq.push(['identify', {email: 'test@gmail.com'}])
  },
  record: (params: RecordParams | undefined) => {
    SmartLook.track('Record', {...params, forms: true } || { forms: true });
  },
  click: (params: ClickParams | undefined) => {
    SmartLook.track('Click', params || {});
    // @ts-ignore
    window._kmq.push(['record', `The user clicked a ${params?.buttonName} button in ${params?.component} component`])
  },
  success: (eventType: string, params: SuccessParams) => {
    SmartLook.track(eventType, params);
    // @ts-ignore
    window._kmq.push(['record', `Success request in ${params?.action} action`])
  },
  error: (message: string | undefined = '') => {
    SmartLook.error(message);
    // @ts-ignore
    window._kmq.push(['record', `Error:: ${message}`])
  },
  log: () => {
    clog('7777777777777->SmartLook', SmartLook)
    clog('7777777777777->TagManager', TagManager)
    // @ts-ignore
    clog('7777777777777->KissMetrics', window._kmq)
  },
  types: {
    click: 'click',
    success: 'success',
    pageLoad: 'pageLoad',
  },
  actions: {
    updateResidentProfile: 'updateResidentProfile',
    saveResident: 'saveResident',
  },
  components: {
    accountSettings: 'AccountSettings',
    accountSettingsEdit: 'AccountSettingsEdit',
    addressConfirmation: 'AddressConfirmation',
    formView: 'FormView',
    contactInformation: 'ContactInformation',
    coverage: 'Coverage',
    myCoverage: 'MyCoverage',
    personalAccess: 'PersonalAccess',
    addYourName: 'AddYourName',
    communityInformation: 'CommunityInformation',
    emailVerification: 'EmailVerification',
    emailLogin: 'EmailLogin',
    moveInDateInformation: 'MoveInDateInformation',
    policyInformationSent: 'PolicyInformationSent',
    policySent: 'PolicySent',
    phoneVerification: 'PhoneVerification',
    switchToCoverageConfirmation: 'SwitchToCoverageConfirmation',
    userInformation: 'UserInformation',
    passwordLessLogin: 'PasswordLessLogin',
    preferences: 'Preferences',
    chooseFile: 'ChooseFile',
  },
}
