import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useDropzone} from "react-dropzone";
import {ImageModal, InformationModal} from "../modals";
import {useHistory, useLocation} from "react-router-dom";
import {QuestionMarkIcon} from "../icons";
import {makeStyles} from "@material-ui/core/styles";
import {toastWarning} from "../../../../core/business/redux/toaster/actions";
import {UniversalButtonGroup} from "../buttons";
import {StoreModel} from "../../../business/models/store";
import {IonButton, IonCol, IonFooter, IonImg, IonRow, IonSpinner} from "@ionic/react";
import {POLICY_INFORMATION_SENT_ROUTE} from "../../../business/enums/routes";
import {colors} from "../../../business/constants/global";
import {style} from "./styles";
import {ICON_PHOTO, ICON_PDF, DECLARATION_EXAMPLE} from "../../../images";
import {startUrls} from "../../../business/constants/startUrls";
import {managerService} from "../../../services";

interface ChooseFileProps {
  files?: any;
  onChange?: any;
  disabled?: any;
  fileUrl?: string;
  setFileUrl?: any;
  hasButton?: boolean;
  existingFileUrl?: string;
  mimeType?: string;
  existingFileType?: string;
  setStep?: any;
}

export const ChooseFile: React.FC<ChooseFileProps> = ({
                                       onChange,
                                       disabled = null,
                                       fileUrl,
                                       setFileUrl,
                                       existingFileUrl = "",
                                       mimeType = "not selected file",
                                       existingFileType = "",
                                       hasButton = true,
                                                        setStep = () => {},
                                     }) => {

  let history = useHistory();
  let {state}: any = useLocation();
  const classes = makeStyles(() => style)();

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenInfo, setIsOpenInfo] = useState(false);

  const {policyDocument} = useSelector((state: StoreModel) => state);

  const dispatch = useDispatch();

  const {getRootProps, getInputProps} = useDropzone({
    onDrop: (acceptedFiles: File[]) => {
      if (!acceptedFiles.length) {
        setFileUrl("");
        return dispatch(toastWarning({className: "small", text: "MESSAGE.FILE_FORMAT_NOT_ALLOWED"}));
      } else
        return onChange(...acceptedFiles, URL.createObjectURL(acceptedFiles[0]));
    },
    accept: {
      "image/*": [],
      "application/pdf": []
    }
  });

  const handleClick = state?.startUrl === startUrls.addressConfirmation
    ? () => setStep(4)
    : () => {
      managerService.click({
        component: managerService.components.chooseFile,
        buttonName: 'CONTINUE'
      })
      history.push({
        pathname: POLICY_INFORMATION_SENT_ROUTE.path,
        state
      })
    }

  const buttons = [
    {
      disabled: !disabled.value || !fileUrl,
      color: colors.cyan,
      value: "BUTTON.CONTINUE",
      onClick: handleClick
    },
  ];

  const getPolicyDocName = () => {
    if (!policyDocument?.document_fields?.fileName && !existingFileUrl) return "View"
    return policyDocument?.document_fields?.fileName;
  }

  return (
    <IonRow>
      <IonCol>
        <h6 className={classes.fileHeader}>Upload or scan your declaration <span>page <QuestionMarkIcon
          onClick={() => setIsOpenInfo(true)}/> </span></h6>
        <div className={"choose-img-box"}>
          <IonRow className="ion-align-items-center">
            <IonCol size="5">
              {!fileUrl && <IonImg class="icon-photo" src={ICON_PHOTO}/>}

              {Boolean(fileUrl) && ["image/png", "image/jpeg"].includes(mimeType) &&
                <IonImg class="icon-photo" src={fileUrl}/>}

              {((Boolean(fileUrl) && mimeType === "application/pdf") || existingFileType === "pdf") &&
                <IonImg class="icon-photo" src={ICON_PDF}/>}
            </IonCol>

            {!Boolean(fileUrl) &&
              <IonCol className={'upload-picture'}>
                <IonButton id="open-modal" fill="clear"
                           className="view-picture-btn ion-no-padding">{getPolicyDocName()}</IonButton>
                <p className="upload-img-text">Add or scan declaration page</p>
              </IonCol>
            }

            {Boolean(fileUrl) && ["image/png", "image/jpeg"].includes(mimeType) &&
              <IonCol className={'upload-picture'}>
                <p className="view-picture-btn ion-no-padding" style={{cursor: 'pointer'}}
                   onClick={() => setIsOpen(true)}>{getPolicyDocName()}</p>
              </IonCol>
            }

            {((Boolean(fileUrl) && mimeType === "application/pdf") || existingFileType === "pdf") &&
              <IonCol
                className={`upload-picture ${(fileUrl && policyDocument?.success) || existingFileUrl ? "active" : ""}`}>
                <a id="open-modal" className="view-picture-btn ion-no-padding" href={fileUrl || existingFileUrl}
                   download={getPolicyDocName()}>{getPolicyDocName()}</a>
              </IonCol>
            }
          </IonRow>
          {
            Boolean(!fileUrl) &&
            <div className="dropzone-wrapper">
              <div {...getRootProps({className: "dropzone"})}>
                <input {...getInputProps()} />
              </div>
            </div>
          }
        </div>

        <IonSpinner name="crescent" className="load-img-spinner"></IonSpinner>

        {fileUrl && policyDocument?.document_fields?.mimeType !== "application/pdf" && <ImageModal fileUrl={fileUrl} isOpen={isOpen} setIsOpen={setIsOpen}/>}

        {!fileUrl &&
          <h6 style={{color: '#EF5041'}}>You can make a picture or add documents in .pdf, .jpg, .png, .doc formats
            only.</h6>}

        <IonFooter>
          {
            hasButton && (
              <UniversalButtonGroup
                buttons={buttons}
              />
            )
          }
          {
            Boolean(fileUrl) &&
            <div className="dropzone-custom-button">
              Replace this coverage and add another document
              <div className="dropzone-wrapper">
                <div {...getRootProps({className: "dropzone"})}>
                  <input {...getInputProps()} />
                </div>
              </div>
            </div>
          }
        </IonFooter>
      </IonCol>


      <InformationModal
        isOpen={isOpenInfo}
        setIsOpen={setIsOpenInfo}
        headerText={'What is a declarations page?'}
        contextText={
          <>
            <p>
              Your renters insurance declarations page (aka cerfificate of insurance or Acord page) is provided by your
              insurance company as proof of coverage. It provides a summary of the coverage provided in your renters
              insurance policy and is usually the first page of your policy.
            </p>

            <IonImg src={DECLARATION_EXAMPLE}/>

            <p style={{color: '#EF5041'}}>
              <strong>Important!</strong><br/>
              Your declaration page might look differently from the example.
            </p>
          </>}
      />
    </IonRow>
  );
};
