import React, {useEffect} from "react";
import {UniversalButtonGroup} from "../../components/primitive/buttons";
import {HeaderSection} from "../../components/primitive/headerSection";
import {Heading} from "../../components/primitive/heading";
import {useHistory, useLocation} from "react-router-dom";
import {fetchSetToPdlw, syncLocalResident} from "../../business/redux/reducers/resident/actions";
import {useDispatch, useSelector} from "react-redux";
import {StoreModel} from "../../business/models/store";
import {Wrapper} from "../../../core/styles";
import {IonCol, IonContent, IonFooter, IonRow} from "@ionic/react";
import {CHOOSE_INSURANCE_ROUTE, MY_COVERAGE_ROUTE} from "../../business/enums/routes";
import {colors} from "../../business/constants/global";
import {ICON_ALLERT} from "../../images";
import {coverageSwitchTypes} from "../../business/constants/startUrls";
import {managerService} from "../../services";

export const SwitchToCoverageConfirmation: React.FC = () => {

  let {state} = useLocation();

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch<any>(syncLocalResident())
  }, [])

  const { resident } = useSelector( (state: StoreModel) => state )

  let history = useHistory();

  const redirectToMyCoverage = () => history.push(MY_COVERAGE_ROUTE.path)

  // @ts-ignore
  const switchType = state?.type;

  const buttons = switchType && switchType === coverageSwitchTypes.pdlwToHo4
    ? [
      {
        color: colors.cyan,
        value: "BUTTON.ADD_NEW_INSURE",
        onClick: () => {
          managerService.click({
            component: managerService.components.switchToCoverageConfirmation,
            buttonName: 'ADD_NEW_INSURE'
          })
          history.push(CHOOSE_INSURANCE_ROUTE.path)
        }
      },
      {
        color: colors.white,
        value: "BUTTON.NO_GO_BACK",
        onClick: () => {
          managerService.click({
            component: managerService.components.switchToCoverageConfirmation,
            buttonName: 'NO_GO_BACK'
          })
          history.push(MY_COVERAGE_ROUTE.path)
        }
      },
    ] : [
      {
        color: colors.cyan,
        value: "BUTTON.YES_CONTINUE",
        onClick: () => dispatch<any>(fetchSetToPdlw(resident?.resident?.uuid, () => {
          managerService.click({
            component: managerService.components.switchToCoverageConfirmation,
            buttonName: 'YES_CONTINUE'
          })
          redirectToMyCoverage()
        })),
      },
      {
        color: colors.white,
        value: "BUTTON.NO_GO_BACK",
        onClick: () => {
          managerService.click({
            component: managerService.components.switchToCoverageConfirmation,
            buttonName: 'NO_GO_BACK'
          })
          redirectToMyCoverage()
        },
      }
    ]

  return (
    <IonContent>
      <Wrapper className="app-container ion-padding">

        <HeaderSection />
        <Heading text={'Are you sure?'} successLogo={ICON_ALLERT}/>
        <IonRow className="ion-justify-content-center ion-padding-bottom ion-margin-bottom">
          <IonCol size="10">
            {switchType === coverageSwitchTypes.ho4ToPdlw &&
              <p className="ion-text-center alert-info-text">Your current renters insurance policy will be deactivated and you will be charged with your rent for <b>Waiver coverage.</b></p>}
            {switchType === coverageSwitchTypes.pdlwToHo4 &&
              <p className="ion-text-center alert-info-text">Your unit will be removed from the <b>Waiver</b> once your new policy has been approved.</p>}
          </IonCol>
        </IonRow>
        <IonFooter>
          <UniversalButtonGroup buttons={buttons} />
        </IonFooter>
      </Wrapper>
    </IonContent>
  );
};
// In this case your current <b>Waiver coverage</b> will be not active (after we approve your new policy)
// Your current renters insurance policy will be deactivated and you will be charged with your rent for Waiver coverage.
