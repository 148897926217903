import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import InsuranceCompanies from "../../components/primitive/insuranceCompanies";
import {ChooseFile} from "../../components/primitive/chooseFile";
import {toastWarning} from "../../../core/business/redux/toaster/actions";
import {StoreModel} from "../../business/models/store";
import {useMediaQuery} from "@mui/material";
import {ENUMS} from "../../../core/styles";

interface Props {
  step: number;
  setStep?: any;
  selectedInsuranceCompany: any;
  setSelectedInsuranceCompany?: any;
  uploadPolicy?: any;
  otherCompany?: string;
  setOtherCompany?: any;
  fileUrl?: string;
  setFileUrl?: any;
  resident?: any;
  hasButton?: boolean;
  recordType?: string;
  openInsureInfo?: any;
  openFileInfo?: any;
}

export const SelectInsuranceData: React.FC<Props> = ({
                                                       step,
                                                       setStep,
                                                       selectedInsuranceCompany,
                                                       setSelectedInsuranceCompany,
                                                       otherCompany,
                                                       setOtherCompany,
                                                       uploadPolicy,
                                                       fileUrl,
                                                       setFileUrl,
                                                       resident,
                                                       hasButton = true,
                                                       recordType,
                                                       openInsureInfo,
                                                       openFileInfo,
                                              }) => {
  useEffect(() => {
    if (resident?.secondary?.insurance_company_selected) {
      setSelectedInsuranceCompany({text: resident?.secondary?.insurance_company_selected});
    }
  }, [resident?.secondary?.insurance_company_selected])
  const dispatch = useDispatch()
  const { policyDocument } = useSelector((state: StoreModel) => state)

  const mimeType = policyDocument?.document_fields?.mimeType || "not selected file"

  const existingFileUrl = resident?.secondary?.temp_url || ""

  const existingFileType = resident?.resident?.secondary?.aws_poi?.slice(resident?.resident?.secondary?.aws_poi?.length - 3)

  const isMobile = useMediaQuery(`(max-width:${ENUMS.MOBILE_WIDTH})`);

  const redirectFile = (selectedInsuranceCompany) => {
    if (selectedInsuranceCompany.value) {
      // @ts-ignore
      window._kmq.push(['record', 'User chooses the insurance company and uploads policy'])
      return (file, url) => {
        dispatch(uploadPolicy(
          selectedInsuranceCompany.text === "Other"
            ? selectedInsuranceCompany.value
            : selectedInsuranceCompany.text,
          file,
          resident.uuid || resident.resident?.uuid,
          recordType,
          isMobile ?'yes' : 'no'
        ));
        setFileUrl(url);
        setStep(3)
      };
    } else return () => dispatch(toastWarning({className: "small", text: "MESSAGE.REQUIRED_INSURANCE_COMPANY"}));
  };

  const handleSelect = data => {
    // @ts-ignore
    window._kmq.push(['record', `Insurance company is ${data.text || ''}`])
    setSelectedInsuranceCompany(data);
    setOtherCompany(data.value);
  };

  return (
    <>
      <InsuranceCompanies
        onSelect={handleSelect}
        fileUrl={fileUrl}
        setFileUrl={setFileUrl}
        step={step}
        selectedInsuranceCompany={selectedInsuranceCompany}
        resident={resident}
        openInsureInfo={openInsureInfo}
      />
      <ChooseFile
        onChange={redirectFile(selectedInsuranceCompany)}
        disabled={selectedInsuranceCompany}
        fileUrl={fileUrl}
        setFileUrl={setFileUrl}
        hasButton={hasButton}
        existingFileUrl={existingFileUrl}
        existingFileType={existingFileType}
        mimeType={mimeType}
        setStep={setStep}
      />
    </>
  );
};
