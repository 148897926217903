import { Dispatch } from "redux";
import { configs } from "../../../../../configs";
import unitsMock from "../../../mocks/unit.json";
import { fetchApi } from "../../../../../core/business/services/api";
import { LOADING } from "../../../../../core/business/redux/loading/types";
import { FETCH_UNITS_BY_PR_ID_SUCCESS , FETCH_UNITS_BY_PR_ID_FAILURE } from "./types";
import {managerService} from "../../../../services";

export const getUnitList = prId => async  (dispatch: Dispatch) => {

  try {
    dispatch({
      type: LOADING,
      loadingText: "PROGRESS.LOADING"
    });
    if (!prId) {
      return;
      // dispatch({
      //   type: FETCH_UNITS_BY_PR_ID_FAILURE,
      //   propertyNames: {
      //     success: false,
      //     message: "MESSAGE.NOT_SELECTED_PROPERTY"
      //   }
      // });
    }

    // const units = unitsMock
    const units = [
      { unit_no: 1111111, u_id: 1111111 },
      { unit_no: 2222222, u_id: 2222222 },
      { unit_no: 3333333, u_id: 3333333 },
      { unit_no: 4444444, u_id: 4444444 },
      { unit_no: 5555555, u_id: 5555555 },
      { unit_no: 6666666, u_id: 6666666 },
      { unit_no: 7777777, u_id: 7777777 },
      { unit_no: 8888888, u_id: 8888888 },
      { unit_no: 9999999, u_id: 9999999 }
    ]

    const response = await fetchApi({
      url: `${configs.api.unit.general}/${prId}`,
      method: "GET",
      mockData: { success: true, units: units }
    });

    dispatch({
      type: FETCH_UNITS_BY_PR_ID_SUCCESS,
      units: response
    });
  } catch (e) {
    managerService.error('In getUnitList method')
  }
}
