import App from "./product/_init";
import {createRoot} from "react-dom/client";
import {SnackbarProvider} from "notistack";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <SnackbarProvider
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    autoHideDuration={5000}
  >
    <App/>
  </SnackbarProvider>
);
