import { combineReducers } from 'redux';
import { configsReducer } from './reducers/configs';
import { cuserReducer } from '../../../core/business/redux/currentUser';
import { initReducer } from './init';
import { insuranceCompaniesReducer } from './reducers/insuranceCompanies';
import { loadingReducer } from '../../../core/business/redux/loading';
import { loadingByTypeReducer } from './reducers/loadingByType';
import { localStorageReducer } from '../../../core/business/redux/localStorage';
import { policyDocumentReducer } from './reducers/policyDocument';
import { residentReducer } from './reducers/resident';
import { routerReducer } from '../../../core/business/redux/router';
import { toasterReducer } from '../../../core/business/redux/toaster';
import { propertyNamesReducer } from "./reducers/property";
import { unitsReducer } from "./reducers/unit";
import { errorsReducer } from "./reducers/errors";

export default combineReducers({
  configs: configsReducer,
  currentUser: cuserReducer,
  init: initReducer,
  insuranceCompanies: insuranceCompaniesReducer,
  loading: loadingReducer,
  loadingByType: loadingByTypeReducer,
  localStorage: localStorageReducer,
  policyDocument: policyDocumentReducer,
  resident: residentReducer,
  routing: routerReducer,
  toaster: toasterReducer,
  propertyNames: propertyNamesReducer,
  units: unitsReducer,
  errors: errorsReducer,
});
