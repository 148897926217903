import React from 'react';
import { useMediaQuery } from '@mui/material';
import MUIPagination from '@mui/lab/Pagination';
import { Div, ENUMS, SPACE } from '../../../styles';

interface PaginationProps {
  onChangePage: Function;
  numberOfPages: number;
  page: number;
}

const Pagination: React.FC<PaginationProps> = ({ onChangePage, page, numberOfPages }) => {
  const isMobile = useMediaQuery(`(max-width:${ENUMS.MOBILE_WIDTH})`);

  if (!numberOfPages) return null;
  return (
    <Div className="pagination" padding={`${SPACE.MEDIUM} 0 `}>
      <MUIPagination
        count={numberOfPages}
        siblingCount={isMobile ? 1 : 2}
        page={page}
        boundaryCount={1}
        showFirstButton={!isMobile}
        showLastButton={!isMobile}
        onChange={(e, pageNumber) => {
          onChangePage(pageNumber);
        }}
      />
    </Div>
  );
};

export default Pagination;
